<template>
  <div class="highlight container">
    <h1 class="title is-2 has-text-centered"><b>HILIGHT</b> PRODUCTS</h1>
    <article class="media has-bg">
      <figure class="media-left">
        <p class="image img-wrapper is-hidden-mobile">
          <img
            :src="require('@/assets/hilights/hilight-3.png')"
        
          />
        </p>
        <p class="image img-wrapper is-hidden-desktop">
          <img
            :src="require('@/assets/hilights/highlight-3-m.png')"
        
          />
        </p>
      </figure>
      <div class="media-content">
        <nav class="level is-mobile">
          <div class="level-left">
            <span class="product-title">Tripoli</span>
          </div>
          <div class="level-right">
            <span class="product-serie">1HT-713</span>
          </div>
        </nav>
        <div class="content has-text-left">
          <p>
            <b>Need a little extra head support?</b><br />
            As the same category our Tripoli has supportive foam back with a
            triple-cushion design. In addition to superior comfort, Tripoli has
            a sleek, stylish silhouette that looks right at home in a modern
            retreat.<br />
          </p>
          <p>
            This updated model features <b>Wireless Remote Control</b> that
            allows you to adjust the back, leg rest, headrest and lumbar for an
            unlimited number of relaxing positions. Plus, a USB charging port
            and two memory settings that save your favorite reclining positions.
          </p>
        </div>
      </div>
    </article>

    <article class="media warp-reverse">
      <div class="media-content">
        <nav class="level is-mobile">
          <div class="level-left">
            <span class="product-title">Sterling</span>
          </div>
          <div class="level-right">
            <span class="product-serie">19P-612</span>
          </div>
        </nav>
        <div class="content">
          <p>
            19P is another new prefix from our Thailand factory, which means
            electric power one seat recliner with swivel base. This power
            recliner has <b>two control buttons on the side panel</b>, which
            allows you to recline the backrest with footrest opening in the same
            time.
          </p>
          <p>
            Steel swivel base can provide the 180 degrees of spin, so it is
            easily for you to get access into the recliner, seeing or exist to
            different direction.
          </p>
        </div>
      </div>
      <figure class="media-right">
        <p class="image img-wrapper is-hidden-mobile">
          <img
            :src="require('@/assets/hilights/hilight-2.png')"
            width="512px"
          />
        </p><p class="image img-wrapper is-hidden-desktop">
          <img
            :src="require('@/assets/hilights/highlight-2-m.png')"
            width="512px"
          />
        </p>
      </figure>
    </article>

    <article class="media has-bg">
      <figure class="media-left">
        <p class="image img-wrapper is-hidden-mobile">
          <img
            :src="require('@/assets/hilights/hilight-1.png')"
            width="512px"
          />
        </p>
        <p class="image img-wrapper is-hidden-desktop">
          <img
            :src="require('@/assets/hilights/highlight-1-m.png')"
            width="512px"
          />
        </p>
      </figure>
      <div class="media-content">
        <nav class="level is-mobile">
          <div class="level-left">
            <span class="product-title">Napier</span>
          </div>
          <div class="level-right">
            <span class="product-serie">TBR-551</span>
          </div>
        </nav>
        <div class="content">
          <p>
            Our popular lift chair series now includes a new, petite scale
            model. <b>The Napier Bronze Power Lift Recliner</b> is designed for
            smaller users, with a shorter seat height and a one-piece chaise
            seat for continuous comfort.
          </p>
          <p>
            Just press a button on the wired remote to get in or out, or fully
            recline. The heavy-duty tubular steel base gives Napier a strong,
            solid foundation. And, its causal style blends with your existing
            pieces. Best of all, this chair can be placed just 2 inches from a
            wall, so it fits beautifully in small spaces
          </p>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
export default {
  created() {
    // document.getElementsByTagName(
    //   "body"
    // )[0].style = `background-image: url('${require("@/assets/hilight-bg.png")}')`;
  },
  beforeDestroy() {
    document.getElementsByTagName("body")[0].style = null;
  },
};
</script>
<style lang="scss" scoped>
@import "~bulma";
@import './../assets/css/_highlight.scss';

@media screen and (min-width: $desktop) {
  .container {
    width: 92%;
  }
}

.highlight {
  margin-top: 2.5rem;
}
h1.title {
  color: white;
  letter-spacing: 1rem;
  font-family: "Helvetica Neue LT W05 25 Ult Lt";

  b {
    font-family: "Helvetica Neue LT W05 45 Light";
  }
}

.media {
  margin-top: 4rem;

  + .media {
    border: none;
    padding-top: 0;
    margin-top: 4rem;
  }

  &.has-bg {
    background: #22284773;
  }

  .product-title {
    font-weight: bold;
    font-family: "Helvetica Neue LT W01_75 Bold";
    letter-spacing: 0.5rem;
    color: #2966a0;
    @extend .title;
    @extend .is-1;
  }

  .product-serie {
    font-family: "Helvetica Neue LT W05 35 Thin";
    font-size: 1.3rem;
  }

  .media-content {
    padding: 1.5rem 1.5rem 1.5rem 0rem;
    font-family: "Helvetica Neue LT W05 35 Thin";
  }

  @media screen and (max-width: $tablet) {
    flex-wrap: wrap;

    .media-right,
    .media-left {
      margin: auto;
    }

    &.warp-reverse {
      flex-wrap: wrap-reverse;
    }

    .media-content {
      padding: 1.5rem 1rem 1.5rem 1rem;
    }
  }
}

</style>